<div class="message-container text-center px-2 cbsw-success-booking-step">
  <div class="mb-1 cbsw-success-booking-message">
    <app-csw-text [text]="successTitleTemplate | translation: 'template_content': lang" customClass="fw-600" />
  </div>

  <div class="cbsw-success-booking-sub-message">
    <app-csw-text [text]="successTemplate | translation: 'template_content': lang" />
  </div>

  <div class="mt-4 cbsw-success-booking-next-buttons-section">
    <app-button
      id="{{ 'booking-success-view-book-next-btn' }}"
      (clickEvent)="onAdditionalBooking()"
      [fontColor]="widgetService?.widgetConf?.button_font_color"
      [backgroundColor]="widgetService?.widgetConf?.button_background_color"
    >
      {{ 'booking_components.bookNextAppointment' | translate }}
    </app-button>

  </div>

  @if (
    bookingService.partnerData?.is_redirect_after_booking_enabled &&
    bookingService.partnerData?.successful_booking_redirect_url &&
    bookingService.partnerData?.redirect_after_booking_action === bookingRedirectionViews.BOOKING_SUCCESS_VIEW
  ) {
    <div class="mt-4 cbsw-success-booking-next-redirection-section">
      <app-button
        id="{{ 'booking-success-view-book-redirection-btn' }}"
        (clickEvent)="redirectTo()"
        [fontColor]="widgetService?.widgetConf?.button_font_color"
        [backgroundColor]="widgetService?.widgetConf?.button_background_color"
      >
        {{ redirectionButtonTemplate | translation: 'template_content': lang }}
      </app-button>
    </div>
  }
</div>
