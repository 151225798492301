@if (showGhostElement) {
  <div class="mt-4">
    <div class="skeleton-box-card">
      <div class="row">
        @for (ghostElement of slotGhostElements; track ghostElement) {
          <div class="col-md-12 mb-4">
            <div class="d-flex stats-card shadow">
              <div class="skeleton-animation w-100 d-flex justify-content-center flex-column align-items-center">
                <div>
                  <div class="stat-box-1"></div>
                  <div class="stat-box mt-1"></div>
                </div>
              </div>
            </div>
          </div>
        }
      </div>
    </div>
  </div>
}

@if (!showGhostElement) {
  <form #storeForm="ngForm">
    <!-- Store Card View Starts -->
    @if (
      stores?.length &&
      widgetService?.widgetConf?.partner?.enable_store_postcode_feature !== 1 &&
      widgetService?.widgetConf?.partner?.show_stores_as_dropdown !== 1
    ) {
      <div class="row cbsw-store-selection-step">
        <div class="col-md-12">
          <div>
            <app-csw-text
              customClass="step-element-title fw-600"
              [text]="storeLabelTemplate | translation: 'template_content': lang | resolvePlaceholder: { store: bookingService.selectedBookingData.currentSelectedAppointmentState.selectedStore } | calioSafe: 'html'
              "
            />
          </div>
        </div>
        @if (stores?.length > 1 && bookingService?.partnerData?.widget_show_all_stores_card === 1) {
          <div class="mt-3 col-md-6 cursor-pointer">
            <app-info-card
              [showCardBorder]="true"
              [showShadow]="false"
              [showBody]="true"
              [selectedCardColor]="widgetService?.widgetConf?.header_background_color"
              [isSelected]="selectedStoreId === 0"
            >
              <div cardBody (click)="onStoreChange(0)">
                <div class="d-flex align-items-center justify-content-between">
                  <div>
                    <app-csw-text
                      [text]="anyStoreLabelTemplate | translation: 'template_content': lang | calioSafe: 'html'"
                      customClass="fw-600 fw-13"
                    />
                  </div>
                  <div class="next-arrow-container">
                    @if (selectedStoreId === 0) {
                      <span (click)="next()">
                        <img src="https://my.calenso.com/images/next_icon_widget.svg" width="26px">
                      </span>
                    }
                  </div>
                </div>
              </div>
            </app-info-card>
          </div>
        }
        @for (store of stores; track store) {
          <div class="mt-3 col-md-6 cursor-pointer">
            <app-info-card
              [showCardBorder]="true"
              [showShadow]="false"
              [showBody]="true"
              [selectedCardColor]="widgetService?.widgetConf?.header_background_color"
              [isSelected]="selectedStoreId === store.id"
              [isDisabled]="store.disabled"
            >
              <div cardBody (click)="onStoreChange(store.id)">
                <div class="d-flex align-items-center justify-content-between">
                  <div>
                    <div>
                      <app-csw-text
                        [text]="store | translation: 'name': lang | calioSafe: 'html'"
                        customClass="fw-600 fw-13"
                      />
                    </div>
                    @if (store.is_online === 0) {
                      <div class="cbw-description">
                        @if (store.street) {
                          <span>{{ store.street }}</span>
                        }
                        @if (store?.zip) {
                          <span>{{ ', ' + store?.zip }}</span>
                        }
                        @if (store.city) {
                          <span>{{ ' ' + store.city }}</span>
                        }
                        @if (store?.country?.name) {
                          <span>{{ ', ' + store?.country?.name }}</span>
                        }
                      </div>
                    }
                  </div>
                  <div class="next-arrow-container">
                    @if (selectedStoreId === store.id) {
                      <span (click)="next()">
                        <img src="https://my.calenso.com/images/next_icon_widget.svg" width="26px">
                      </span>
                    }
                  </div>
                </div>
              </div>
            </app-info-card>
          </div>
        }
      </div>

      @if (showStoreSelectionRequiredError) {
        <div class="cbsw-meeting-type-selection-setp-error cbsw-global-step-error">
          <div class="text-danger">
            <div>{{ 'error_messages.select_one_option' | translate }}</div>
          </div>
        </div>
      }
    }
    <!-- Store Card View Ends -->
    <!-- Stores dropdown view starts -->
    @if (
      stores?.length > 0 &&
      widgetService?.widgetConf?.partner?.enable_store_postcode_feature !== 1 &&
      widgetService?.widgetConf?.partner?.automatic_store_zip_search !== 1 &&
      widgetService?.widgetConf?.partner?.show_stores_as_dropdown === 1
    ) {
      <div class="row cbsw-store-selection-step">
        <div class="col-md-12">
          <app-csw-text
            customClass="step-element-title fw-600"
            [text]="storeLabelTemplate | translation: 'template_content': lang | resolvePlaceholder: { store: bookingService.selectedBookingData.currentSelectedAppointmentState.selectedStore } | calioSafe: 'html'"
          />
        </div>
        <div class="col-md-12">
          <ng-select
            class="mt-3"
            id="store"
            name="store"
            placeholder=""
            [multiple]="false"
            [(ngModel)]="selectedStoreId"
            (change)="onStoreChange($event)"
            [required]="true"
            #storeControl="ngModel"
            [ngClass]="{ 'is-invalid': storeControl.touched && storeControl.invalid }"
          >
            @if (stores?.length > 1 && bookingService?.partnerData?.widget_show_all_stores_card === 1) {
              <ng-option [value]="0">
                {{ anyStoreLabelTemplate | translation: 'template_content': lang }}
              </ng-option>
            }
            @for (store of stores; track store.id) {
              <ng-option [value]="store.id" [disabled]="store.disabled">
                {{ store | translation: 'name': lang }}
              </ng-option>
            }
          </ng-select>
          @if (storeControl.touched && !storeControl.valid) {
            <div class="text-danger">
              @if (storeControl?.errors?.required) {
                {{ 'error_messages.required_error_message' | translate }}
              }
            </div>
          }
        </div>
      </div>
    }
    <!-- Stores dropdown view ends -->
    <!-- Zip Code Stores dropdown view starts -->
    @if (
      (widgetService?.widgetConf?.partner?.enable_store_postcode_feature === 1 &&
      widgetService?.widgetConf?.partner?.show_stores_as_dropdown === 1) ||
      widgetService?.widgetConf?.partner?.automatic_store_zip_search === 1
    ) {
      <div class="row cbsw-store-selection-step">
        <div class="col-md-12">
          <app-csw-text
            customClass="step-element-title fw-600"
            [text]="storeLabelTemplate | translation: 'template_content': lang | resolvePlaceholder: { store: bookingService.selectedBookingData.currentSelectedAppointmentState.selectedStore } | calioSafe: 'html'"
          />
        </div>
        <div class="col-md-12">
          <ng-select
            class="mt-3"
            [loading]="isStoreLoading"
            id="store"
            name="store"
            #storeControl="ngModel"
            placeholder=""
            typeToSearchText="{{ 'booking_components.search_store_postcode_helper_text' | translate }}"
            notFoundText="{{ 'booking_components.store_not_found' | translate }}"
            [multiple]="false"
            [(ngModel)]="selectedStoreId"
            [required]="true"
            [ngClass]="{ 'is-invalid': storeControl.touched && storeControl.invalid }"
            [typeahead]="searchStores"
            [searchable]="true"
            (change)="onStoreChange($event)"
            (keydown)="allowOnlyNumbers($event)"
          >
            @for (store of stores; track store.id) {
              <ng-option [value]="store.id" [disabled]="store.disabled">
                <span>{{ store | translation: 'name': lang }}</span>
                @if (store.distance && widgetService?.widgetConf?.partner?.show_distance_in_stores === 1) {
                  <span>
                    ({{ 'booking_components.distance_from_store' | translate: {distance: (store.distance | number:'1.0-0')} }})
                  </span>
                }
              </ng-option>
            }
            <ng-template ng-loadingspinner-tmp>
              <div class="lds-ellipsis">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
              </div>
            </ng-template>
          </ng-select>
          <div>
            <small class="text-muted">
              {{ 'booking_components.search_store_by_postcode_placeholder' | translate }}
            </small>
          </div>
          @if (storeControl.touched && !storeControl.valid) {
            <div class="text-danger">
              @if (storeControl?.errors?.required) {
                {{ 'error_messages.required_error_message' | translate }}
              }
            </div>
          }
        </div>
      </div>
    }

    <!-- Zip Code Stores dropdown view ends -->
    <div class="d-flex justify-content-end mt-4 cbw-custom-navigation">
      @if (showPreviousButton) {
        <div class="me-2">
          <app-button
            id="{{ 'store-selection-previous-btn' }}"
            (clickEvent)="previous()"
            [fontColor]="widgetService?.widgetConf?.button_font_color"
            [backgroundColor]="widgetService?.widgetConf?.button_background_color"
          >
            <span class="custom-button-icon back"></span>
            {{ ((bookingService.widgetTemplates | calioMeetingTemplate: { id: 129, type: 'template'}) | translation: 'template_content': lang) }}
          </app-button>
        </div>
      }
      <div>
        <app-button
          id="{{ 'store-selection-next-btn' }}"
          (clickEvent)="next()"
          [fontColor]="widgetService?.widgetConf?.button_font_color"
          [backgroundColor]="widgetService?.widgetConf?.button_background_color"
        >
          {{ ((bookingService.widgetTemplates | calioMeetingTemplate: { id: 128, type: 'template' }) | translation: 'template_content': lang) }}
          <span class="custom-button-icon next"></span>
        </app-button>
      </div>
    </div>
  </form>
}
