import { NgClass, NgTemplateOutlet } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, DestroyRef, EventEmitter, inject, OnInit, Output } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { BOOKING_PAGE_VIEWS, GTM_EVENTS, WIDGET_TEMPLATES_CONST } from '@constants/app.constants';
import { AppointmentServiceCategoryModel, AppointmentServiceModel } from '@models/appointment-service.model';
import { CurrentViewData } from '@models/widget-conf.model';
import { WidgetTemplateModel } from '@models/widget-template.model';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { CalioCurrencyPipe } from '@pipes/calio-currency.pipe';
import { CalioMeetingTemplatePipe } from '@pipes/calio-meeting-template.pipe';
import { CalioSafePipe } from '@pipes/calio-safe.pipe';
import { CalioTranslationPipe } from '@pipes/calio-translation.pipe';
import { HourReplacePipe } from '@pipes/hour-replace.pipe';
import { MinuteReplacePipe } from '@pipes/minute-replace.pipe';
import { ReplaceCommaPipe } from '@pipes/replace-comma.pipe';
import { BookingService } from '@services/feature/booking.service';
import { CustomEventService } from '@services/feature/custom-event.service';
import { WidgetService } from '@services/feature/widget.service';
import { GoogleAnalyticsService } from '@services/utils/google-analytics.service';
import { HelperService } from '@services/utils/helper.service';
import { LoggerService } from '@services/utils/logger.service';
import { SwRouteService } from '@services/utils/sw-route.service';
import { AlertComponent } from '@ui-lib/alert/alert.component';
import { ButtonComponent } from '@ui-lib/buttons/button/button.component';
import { InfoCardComponent } from '@ui-lib/cards/info-card/info-card.component';
import { CswLinkComponent } from '@ui-lib/typography/csw-link/csw-link.component';
import { CswTextComponent } from '@ui-lib/typography/csw-text/csw-text.component';
import { catchError, forkJoin, of, switchMap } from 'rxjs';
import { Observable } from 'rxjs/internal/Observable';
import { SwiperModule } from 'swiper/angular';

const pipes = [CalioMeetingTemplatePipe, CalioCurrencyPipe, CalioSafePipe, CalioTranslationPipe, MinuteReplacePipe, HourReplacePipe, ReplaceCommaPipe];
const components = [AlertComponent, CswTextComponent, ButtonComponent, InfoCardComponent, CswLinkComponent];
@Component({
  selector: 'app-services-selection',
  templateUrl: './services-selection.component.html',
  styleUrls: ['./services-selection.component.scss'],
  standalone: true,
  imports: [SwiperModule, NgTemplateOutlet, NgClass, TranslateModule, ...components, ...pipes]
})
export class ServicesSelectionComponent implements OnInit {

  private customEventService = inject(CustomEventService);
  private googleAnalyticsService = inject(GoogleAnalyticsService);
  private helperService = inject(HelperService);
  private swRouteService = inject(SwRouteService);
  private translate = inject(TranslateService);
  protected bookingService = inject(BookingService);
  protected widgetService = inject(WidgetService);
  private destroyRef = inject(DestroyRef);

  private isGTMEventSent: boolean;
  private supportedAppointmentServiceIds: number[];
  protected showServiceGhostElement = false;
  protected showAdditionalServiceGhostElement = false;
  protected ghostElement = new Array<number>(4);
  protected lang: string;
  protected serviceLabelTemplate: WidgetTemplateModel;
  protected additionalServiceLabelTemplate: WidgetTemplateModel;
  protected services: AppointmentServiceModel[];
  protected additionalServices: AppointmentServiceModel[];
  protected showErrorMessage = false;
  protected showServiceSelectionRequiredError = false;
  protected servicesGroupedData: {
    key: string,
    temp: {
      appointment_service_category: AppointmentServiceCategoryModel,
    },
    value: AppointmentServiceModel[],
  }[] = [];
  protected additionalServicesGroupedData: {
    key: string,
    temp: {
      appointment_service_category: AppointmentServiceCategoryModel,
    },
    value: AppointmentServiceModel[],
  }[] = [];

  @Output() nextPageEvent = new EventEmitter<CurrentViewData>();
  @Output() previousPageEvent = new EventEmitter<CurrentViewData>();

  constructor() {
    this.lang = this.translate.getDefaultLang();
    this.translate.onLangChange.subscribe(language => this.lang = language.lang);
  }

  ngOnInit(): void {
    this.isGTMEventSent = false;
    this.bookingService.loadAppointmentStateFromLocalStorage();
    this.supportedAppointmentServiceIds = this.bookingService.getSupportedAppointmentServiceIdsFromLocalStorage(
      this.bookingService.partnerData.booking_name
    );
    if (this.widgetService?.widgetConf?.context?.show_meeting_type_picker && this.bookingService?.selectedBookingData?.currentSelectedAppointmentState?.selectedMeetingTypeId !== 1) {
      this.getAppointmentSevicesByMeetingTypes();
    } else {
      // show_meeting_type_picker: Meeting type picker is disabled; setting meeting provider to 1
      this.bookingService.selectedBookingData.currentSelectedAppointmentState.selectedMeetingTypeId = 1;
      this.getAppointmentSevicesByStoreMeetingType();
    }
    this.customEventService.toggleFillContainer.emit(true);
    this.setupTemplates();
  }

  private getAppointmentSevicesByMeetingTypes(): void {
    const meetingTypeId = this.bookingService?.selectedBookingData?.currentSelectedAppointmentState?.selectedMeetingTypeId;
    const storeId = this.widgetService.widgetConf?.context?.filter_meetingtype_services_based_on_store_id ? this.bookingService?.selectedBookingData?.currentSelectedAppointmentState?.selectedStoreId : undefined;
    this.showServiceGhostElement = true;
    this.bookingService.getAppointmentSevicesByMeetingType(meetingTypeId, storeId)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: (result) => this.handelServices(result, meetingTypeId),
        error: (error: HttpErrorResponse) => this.handelServicesError(error)
      });
  }

  private getAppointmentSevicesByStoreMeetingType(): void {
    const storeId = this.bookingService?.selectedBookingData?.currentSelectedAppointmentState?.selectedStoreId;
    const selectedStoreUuids = this.bookingService?.selectedBookingData?.currentSelectedAppointmentState?.selectedStoreUuids;
    const workerId = this.bookingService?.selectedBookingData?.currentSelectedAppointmentState?.selectedWorkerId;
    const workerEmail = this.widgetService.widgetConf?.context?.worker_email;
    this.showServiceGhostElement = true;
    const meetingTypeId = this.bookingService.selectedBookingData.currentSelectedAppointmentState.selectedMeetingTypeId;

    this.bookingService.getAppointmentSevices({
      store_id: selectedStoreUuids?.length ? null : storeId,
      worker_id: workerId || null,
      worker_email: workerEmail || null,
    })
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: result => this.handelServices(result, meetingTypeId),
        error: (error: HttpErrorResponse) => this.handelServicesError(error)
      });
  }

  private handelServices(result: AppointmentServiceModel[], meetingTypeId: number): void {
    if (result) {
      this.services = result;
      if (!this.widgetService.widgetConf.context.is_internal) {
        this.services = this.services.filter(service => service.is_internal !== 1);
      }

      const filteredService = this.filterBySupportedServicesAndCategory(this.services);

      // show supported appointment service ids if present; in-case not present then show all the services
      filteredService.length > 0 && (this.services = filteredService);

      LoggerService.log(this.bookingService?.selectedBookingData?.currentSelectedAppointmentState?.selectedMeetingTypeId);
      this.services.forEach(service => service.meeting_provider_id = meetingTypeId);

      this.servicesGroupedData = this.setupAppointmentServiceCategories(this.services);
      this.showServiceGhostElement = false;
      if (this.services?.length === 1) {
        this.onSelect(this.services[0], false);
        if (this.bookingService.lastView !== BOOKING_PAGE_VIEWS.WORKERS_VIEW) {
          // TODO: Need to check why we have differenet behaviour for different meeting types
          if (meetingTypeId === 1) {
            // check if service is pre-selected via URL param then skip step even though auto skip is disabled
            if (this.swRouteService.isAutoSkipStepsEnabled() || this.widgetService.isDefaultAppointmentServiceIdsSelected()) {
              this.next(false);
            }
          } else {
            this.next(false);
          }
        }
      } else if (
        this.services?.length > 1 &&
        this.bookingService.selectedBookingData.currentSelectedAppointmentState?.selectedServiceIds?.length > 0
      ) {

        const findService = this.services.find(service => this.bookingService.selectedBookingData.currentSelectedAppointmentState.selectedServiceIds?.indexOf(service.id) > -1);
        findService && this.onSelect(findService, false);

        if (this.bookingService.selectedBookingData?.selectedServices?.length > 0) {
          if (this.widgetService.isDefaultMeetingTypeIdSelected()) {
            if (this.bookingService.lastView === BOOKING_PAGE_VIEWS.WORKERS_VIEW) {
              this.previous();
            } else {
              this.next(false);
            }
          } else {
            if (this.bookingService.lastView !== BOOKING_PAGE_VIEWS.WORKERS_VIEW) {
              if (meetingTypeId === 1) {
                if (Number(this.bookingService.partnerData.is_widget_postcode_worker_random_selection_enabled) === 0) {
                  this.next(false);
                }
              } else {
                this.next(false);
              }
            }
          }
        }
      }

      // Check for show_additional_services_provided_by_meeting_providers
      if (this.widgetService.widgetConf?.context?.show_additional_services_provided_by_meeting_providers?.length) {
        if (this.bookingService.partnerData.allow_multiple_services_bookings !== 1) {
          this.checkforAdditionalServicesSuggestion(this.widgetService.widgetConf.context.show_additional_services_provided_by_meeting_providers);
        } else {
          LoggerService.warn('[debug] show_additional_services_provided_by_meeting_providers will only work if allow_multiple_services_bookings is disabled');
        }
      }
    } else {
      // TODO show error if services are not there
      this.showServiceGhostElement = false;
    }
  }

  private handelServicesError(error: HttpErrorResponse): void {
    this.googleAnalyticsService.emitAppointmentBookingEvent(GTM_EVENTS.appointment_booking_step_failed);
    this.bookingService.gtmStartTriggered = false;
    this.showServiceGhostElement = false;
    this.showErrorMessage = true;
    LoggerService.error(error);
  }

  private filterBySupportedServicesAndCategory(services: AppointmentServiceModel[]): AppointmentServiceModel[] {
    const category = this.widgetService.widgetConf.context?.category;
    // filter supported appointment service ids from all ther services
    return services.filter(service => {
      // Check if supportedAppointmentServiceIds has values, and if so, filter by it
      const isSupported = this.supportedAppointmentServiceIds.length === 0 || this.supportedAppointmentServiceIds.includes(service.id);
      // Check if category is provided and has values, then filter by it
      const isInCategory = !category || category?.length === 0 || category.includes(service.appointment_service_category?.id);
      return isSupported && isInCategory;
    });
  }

  private setupAppointmentServiceCategories(services: AppointmentServiceModel[]) {
    const groupedData = this.helperService.groupBy(
      services,
      'appointment_service_category_id',
      'position',
      'position',
      'appointment_service_category'
    );

    // Sort finalDataList based on temp.appointment_service_category.position
    groupedData.sort((a, b) => {
      const posA = a.temp?.appointment_service_category?.position ?? Number.MAX_VALUE;
      const posB = b.temp?.appointment_service_category?.position ?? Number.MAX_VALUE;
      return posA - posB;
    });

    return groupedData;
  }

  protected next(isUserInteracted = true): void {
    // Service is is not selected and clicked on next then show error.
    if (this.bookingService.selectedBookingData.currentSelectedAppointmentState.selectedServiceIds.length === 0) {
      this.showServiceSelectionRequiredError = true;
      return;
    }
    isUserInteracted && this.googleAnalyticsService.emitAppointmentBookingEvent(GTM_EVENTS.appointment_booking_step_services_end);
    this.bookingService.setAppointmentStateInLocalStorage(this.bookingService.selectedBookingData.currentSelectedAppointmentState);
    this.customEventService.toggleFillContainer.emit(false);
    this.nextPageEvent.emit({ view: BOOKING_PAGE_VIEWS.WORKERS_VIEW, isUserInteracted });
  }

  protected previous(): void {
    this.isGTMEventSent && this.googleAnalyticsService.emitAppointmentBookingEvent(GTM_EVENTS.appointment_booking_step_services_end);
    this.customEventService.toggleFillContainer.emit(false);
    if (
      this.widgetService?.widgetConf?.context?.show_meeting_type_picker &&
      this.bookingService?.selectedBookingData?.currentSelectedAppointmentState?.selectedMeetingTypeId !== 1
    ) {
      this.previousPageEvent.emit({ view: BOOKING_PAGE_VIEWS.MEETING_TYPE_VIEW, isUserInteracted: true });
    } else {
      this.previousPageEvent.emit({ view: BOOKING_PAGE_VIEWS.STORE_VIEW, isUserInteracted: true });
    }
  }

  protected toggleServiceSelection(serviceData: AppointmentServiceModel): void {
    if (this.isSelectedService(serviceData)) {
      this.onDeSelect(serviceData);
    } else {
      this.onSelect(serviceData);
    }
  }

  private onSelect(serviceData: AppointmentServiceModel, isUserInteracted = true): void {
    // Reset Flag
    this.showServiceSelectionRequiredError = false;

    if (isUserInteracted) {
      // trigger start event on first user interaction happen and
      // service selection selection step is configured to first step but
      // only single service selection is present
      this.bookingService.triggerApoointmentStartEvent();

      if (!this.isGTMEventSent) {
        this.googleAnalyticsService.emitAppointmentBookingEvent(GTM_EVENTS.appointment_booking_step_services_start);
        this.isGTMEventSent = true;
      }
    }
    this.bookingService.selectedBookingData.selectedServices.push(serviceData);

    if (this.bookingService.partnerData.allow_multiple_services_bookings === 1) {
      this.bookingService.selectedBookingData.currentSelectedAppointmentState.selectedServiceIds.push(serviceData.id);
      this.bookingService.selectedBookingData.currentSelectedAppointmentState.selectedServices.push(serviceData);
    } else {
      this.bookingService.selectedBookingData.currentSelectedAppointmentState.selectedServiceIds = [serviceData.id];
      this.bookingService.selectedBookingData.currentSelectedAppointmentState.selectedServices = [serviceData];
    }
    this.bookingService.selectedBookingData.currentSelectedAppointmentState.selectedMeetingTypeId = serviceData.meeting_provider_id;

    LoggerService.log('[debug] currentSelectedAppointmentState ', this.bookingService.selectedBookingData.currentSelectedAppointmentState);
    // Proceeds to the next step if the one-page UI is enabled.
    this.widgetService.widgetConf?.context?.enable_one_page_ui && this.next();
  }

  private onDeSelect(serviceData: AppointmentServiceModel): void {
    const serviceIndex = this.bookingService.selectedBookingData.currentSelectedAppointmentState.selectedServiceIds?.indexOf(serviceData.id);
    this.bookingService.selectedBookingData.currentSelectedAppointmentState.selectedServiceIds.splice(serviceIndex, 1);
    this.bookingService.selectedBookingData.currentSelectedAppointmentState.selectedServices.splice(serviceIndex, 1);
    LoggerService.log('[debug] currentSelectedAppointmentState ', this.bookingService.selectedBookingData.currentSelectedAppointmentState);
  }

  private setupTemplates(): void {
    this.serviceLabelTemplate = this.bookingService.widgetTemplates.find(template => template.identifier === WIDGET_TEMPLATES_CONST.WIDGET_APPOINTMENT_SERVICE_LABEL);
    this.serviceLabelTemplate && (this.serviceLabelTemplate.is_multi_language = 1);

    this.additionalServiceLabelTemplate = this.bookingService.widgetTemplates.find(template => template.identifier === WIDGET_TEMPLATES_CONST.ADDITIONAL_SERVICES_BY_ANOTHER_MEETING_PROVIDER);
    this.additionalServiceLabelTemplate && (this.additionalServiceLabelTemplate.is_multi_language = 1);
  }

  private checkforAdditionalServicesSuggestion(supportedAdditionalMeetingProviderIds: number[]): void {
    const supportedMeetingProviderIds = [...this.widgetService.widgetConf?.context?.meeting_providers];

    // Remove selected Meeting type from list
    supportedMeetingProviderIds?.splice(supportedMeetingProviderIds.indexOf(this.bookingService?.selectedBookingData?.currentSelectedAppointmentState?.selectedMeetingTypeId), 1);

    // Filter supportedAdditionalMeetingProviderIds by context meeting_providers
    const filteredAdditionalMeetingProviderIds = supportedAdditionalMeetingProviderIds.filter(id => supportedMeetingProviderIds.includes(id));

    if (filteredAdditionalMeetingProviderIds.length) {
      this.showAdditionalServiceGhostElement = true;
      const additionalServices: Observable<AppointmentServiceModel[]>[] = [];

      // Check if meeting provider is store
      if (filteredAdditionalMeetingProviderIds.includes(1)) {
        const storeId = this.bookingService?.selectedBookingData?.currentSelectedAppointmentState?.selectedStoreId;
        const selectedStoreUuids = this.bookingService?.selectedBookingData?.currentSelectedAppointmentState?.selectedStoreUuids;
        const workerId = this.bookingService?.selectedBookingData?.currentSelectedAppointmentState?.selectedWorkerId;
        const workerEmail = this.widgetService.widgetConf?.context?.worker_email;
        this.showServiceGhostElement = true;

        additionalServices.push(this.bookingService.getAppointmentSevices({
          store_id: selectedStoreUuids?.length ? null : storeId,
          worker_id: workerId || null,
          worker_email: workerEmail || null,
        })
          .pipe(
            switchMap(services => {
              return of(services?.map(service => {
                service.meeting_provider_id = 1;
                service.is_additional_service = 1;
                return service;
              }));
            }),
            catchError(() => of([]))
          ));
        filteredAdditionalMeetingProviderIds?.splice(filteredAdditionalMeetingProviderIds.indexOf(1), 1);
      }

      //Check for other meeting providers
      if (filteredAdditionalMeetingProviderIds.length) {
        filteredAdditionalMeetingProviderIds.forEach(meetingProviderId => {
          additionalServices.push(this.bookingService.getAppointmentSevicesByMeetingType(meetingProviderId)
            .pipe(
              switchMap(services => {
                return of(services?.map(service => {
                  service.meeting_provider_id = meetingProviderId;
                  service.is_additional_service = 1
                  return service;
                }));
              }),
              catchError(() => of([]))
            ));
        });
      }

      forkJoin(additionalServices).subscribe(
        result => {
          let tempServices = Array<AppointmentServiceModel>();
          result.forEach(services => {
            services.length && tempServices.push(...services);
          });

          if (!this.widgetService.widgetConf.context.is_internal) {
            tempServices = tempServices.filter(service => service.is_internal !== 1);
          }

          tempServices = this.filterBySupportedServicesAndCategory(tempServices);

          // [SANITAS] To remove duplicate services based on the same name
          const defaulServiceNames = this.services?.map(service => service?.name?.trim()?.toLowerCase());
          LoggerService.log("defaulServiceNames", defaulServiceNames);
          tempServices = tempServices.filter(service => !defaulServiceNames?.includes(service?.name?.trim()?.toLowerCase()));

          this.additionalServicesGroupedData = this.setupAppointmentServiceCategories(tempServices);
          this.showAdditionalServiceGhostElement = false;
        }
      );

    } else {
      LoggerService.warn('[Debug:show_additional_services_provided_by_meeting_providers] No additional meeting types found for service suggestions.');
    }
  }

  isSelectedService(serviceData): boolean {
    const findIndex = this.bookingService.selectedBookingData.currentSelectedAppointmentState?.selectedServices.findIndex(service => service.id === serviceData.id && (service.meeting_provider_id === serviceData.meeting_provider_id))
    return this.bookingService.selectedBookingData.currentSelectedAppointmentState?.selectedServices?.[findIndex]?.meeting_provider_id === serviceData.meeting_provider_id
  }
}
