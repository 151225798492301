import { inject, Pipe, PipeTransform } from '@angular/core';
import { StoreDbModel } from '@app/models/store-db.model';
import { TranslateService } from '@ngx-translate/core';
import { CalioTranslationPipe } from './calio-translation.pipe';

class IResolvePlaceholderInterface {
  readonly store?: StoreDbModel
}

@Pipe({
  name: 'resolvePlaceholder',
  standalone: true
})
export class ResolvePlaceholderPipe implements PipeTransform {

  private readonly calioTranslationPipe = inject(CalioTranslationPipe);
  private readonly translate = inject(TranslateService);

  private readonly lang = this.translate.getDefaultLang();

  /**
    if (!value) {
      return value;
    }
   * @param value - The input string containing placeholders.
   * @param data - An object containing data to resolve placeholders.
   * @returns The transformed string with placeholders replaced.
   */
  transform(value: string, data: IResolvePlaceholderInterface): string {
    if (!value) return value;

    if (data?.store) {
      value = value.replace(/\[STORE_NAME\]/g, this.calioTranslationPipe.transform(data.store, 'name', this.lang));
    }
    return value;
  }
}
