import { BOOKING_REDIRECTION_VIEWS } from '@constants/app.constants';
import { WorkerDbModel } from '@models/worker-db.model';

export enum TokenType {
  publicSmartwidget_survey = 'public_smartwidget_survey',
  publicSmartwidget = 'public_smartwidget',
}
export class PartnerDbModel {
  id: number;
  name: string;
  booking_name: string;
  logo: string;
  currency: string;
  micro_partner_description: string;
  url: string;
  hide_website_on_bookingsite: number;
  appointment_booking_info: string;
  event_slot_booking_info: string;
  courtesy_form: string;
  street: string;
  language_id: number;
  country_id: number;
  zip: string;
  city: string;
  phone: string;
  hide_mobile_on_bookingsite: number;
  email: string;
  is_business: number;
  widget_header_background_color: string;
  widget_footer_background_color: string;
  widget_text_color: string;
  widget_footer_button_color: string;
  widget_header_active_color: string;
  widget_background_color: string;
  resource_booking_enabled: number;
  booking_interval: number;
  ical_hash: string;
  egal_wer_show: number;
  show_address_on_widget: number;
  show_phone_on_widget: number;
  show_mobile_on_widget: number;
  is_cart_supported: number;
  show_message_on_widget: number;
  appointment_lead_time: number;
  event_lead_time: number;
  hide_back_on_bookingsite: number;
  hide_login_on_bookingsite: number;
  country: CountryDao;
  language: LanguageDao;
  json_web_tokens: { token: string };
  workers: WorkerDbModel[];
  subscription: SubscriptionDao;
  analytics: AnalyticsDao;
  widget_booking_info: WidgetBookingInfoDao;
  allow_multiple_services_bookings: number;
  hide_resource_step: number;
  hide_mail_resource: number;
  future_booking_threshold_appointments: number;
  future_booking_threshold_events: number;
  bookingwidget_enabled: number;
  widget_appointment_service_category_status: number;
  hide_widget_cart_functionality_message: number;
  supported_widget_languages: string[];
  show_stores_as_dropdown: number;
  enable_store_postcode_feature: number;
  ask_for_customer_notification_preference: number;
  disable_automatic_search: number;
  uuid: string;
  enable_services_search: number;
  widget_hide_resource_step_for_default_workers: number;
  widget_disable_search_all_resource_button: number;
  activate_phone_number_verification_feature: number;
  activate_mobile_number_verification_feature: number;
  widget_email_shown: number;
  widget_email_required: number;
  widget_show_lead_creation_button: number;
  show_distance_in_stores: number;
  automatic_store_zip_search: number;
  show_salutation_in_widget: number;
  internal_widget_email_shown: number;
  internal_widget_email_required: number;
  widget_show_all_stores_card: number;
  adobe_launch_tracking_url: string;
  anyone_feature_hide_ressource: number;
  is_redirect_after_booking_enabled: number;
  successful_booking_redirect_url: string;
  redirect_after_booking_action: bookingRedirectionViews = BOOKING_REDIRECTION_VIEWS.BOOKING_SUCCESS_VIEW;
  widget_show_resource_store_name: number;
  scroll_to_element_feature_is_enabled: number;
  courtesyForm: string;
  features: FeaturesDao[];
  internal_token: string;
  is_captcha_feature_enabled: number;
  is_widget_postcode_worker_random_selection_enabled: number;
  show_inline_description: number;
  is_appointment_reservation_enabled: number;
}

export class FeaturesDao {
  feature: FeatureDao;
  feature_id: number;
  id: number;
  partner_id: number;
  subscription_type_id: number;
  value: string;
}

export class FeatureDao {
  category: string;
  id: number;
  name: string;
  type: string;
  uuid: string;
}

export class CountryDao {
  id: number;
  supported: number;
  identifier: string;
}

export class LanguageDao {
  id: number;
  supported: number;
  identifier: string;
}

export class SubscriptionDao {
  id: number;
  partner_id: number;
  subscription_type_id: number;
  employees: number;
  reason: string;
  created: Date;
  start: Date;
  end: Date;
  status: number;
  automatic_renewal: number;
  invoice_url: string;
  payment_type: string;
  months: number;
  price: number;
  trial_mode: number;
  trial_reminder_sent: number;
  setup_price: any;
  worker_id: any;
  sales_provision: number;
}

export class AnalyticsDao {
  UA: string;
  GTM: string;
}

export class WidgetBookingInfoDao {
  id: number;
  identifier: string;
  description: string;
  type: string;
  salutation: string;
  intro: string;
  finish: string;
  footer: string;
  use_custom_logo: number;
  header_hex_color: string;
  courtesy_form: string;
  sms_content: string;
  _locale: string;
  _translations: {
    [key: string]: WidgetBookingInfoDaoTranslation;
  };
}

export class WidgetBookingInfoDaoTranslation {
  locale: string;
}

export type bookingRedirectionViews = typeof BOOKING_REDIRECTION_VIEWS[keyof typeof BOOKING_REDIRECTION_VIEWS];
