import { HttpClient } from '@angular/common/http';
import { DestroyRef, inject, Injectable } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { SUBSCRIPTION_CONSTANTS } from '@constants/app.constants';
import { WINDOW_LISTENER_CONSTANTS } from '@constants/window-listener.constants';
import { environment } from '@environments/environment';
import { StatisticsTraceModel } from '@models/appointment-booking.model';
import { SubscriptionDao } from '@models/partner-db.model';
import { WidgetService } from '@services/feature/widget.service';
import { FeatureService } from '@services/utils/feature.service';
import { LoggerService } from '@services/utils/logger.service';
import { Observable } from 'rxjs';
import { v4 as uuidv4 } from 'uuid';

declare var dataLayer: any;

@Injectable({
  providedIn: 'root',
})
export class GoogleAnalyticsService {

  private http = inject(HttpClient);
  private destroyRef = inject(DestroyRef);
  private featureService = inject(FeatureService);
  private widgetService = inject(WidgetService);

  private sessionUuid: string;
  private _partnerSubscriptionPlan: SubscriptionDao;

  set partnerSubscriptionPlan(subscriptionPlan: SubscriptionDao) {
    this._partnerSubscriptionPlan = subscriptionPlan;
  }

  emitAppointmentBookingEvent(
    eventName: string,
    params: { [key: string]: any } = null
  ): void {
    LoggerService.log('Appointment booking step event ', eventName);
    const type = 'TRACKER_TRIGGER_VIEW_END';
    this.sendAppointmentBookingStepsToParentWebsite(eventName);

    // Google Tag Manager
    try {
      dataLayer.push({
        event: eventName,
        ...params
      });
      LoggerService.log(`[GOOGLE_TAG]:[${eventName}] event was fired.`);
    } catch (error) {
      LoggerService.warn('Datalayer not defined error is handled');
    }

    // Adobe launch tracking
    try {
      const someClick = new CustomEvent(type, {
        detail: {
          name: eventName,
          params
        }
      });
      window.dispatchEvent(someClick);
      LoggerService.log(`[ADOBE_LAUNCH_TRACKING]:[${eventName}] event was fired.`);
    } catch (error) {
      LoggerService.warn('Adobe event error handler');
    }

    // Only enterprise partners can access this feature
    if ((this._partnerSubscriptionPlan?.subscription_type_id === SUBSCRIPTION_CONSTANTS.SUBSCRIPTION_NEW_ENTERPRISE_TYPE.id ||
      this._partnerSubscriptionPlan?.subscription_type_id === SUBSCRIPTION_CONSTANTS.SUBSCRIPTION_ENTERPRISE_TYPE.id)) {
      !this.sessionUuid && this.generateSessionUuid();
      this.emitBookingDetails(eventName, params);
    }
  }

  sendAppointmentBookingStepsToParentWebsite(eventName: string): void {
    if (this.widgetService?.parentToChildOriginEvent) {
      this.widgetService.sendMessageToMainJs(
        this.widgetService.parentToChildOriginEvent,
        {
          event: WINDOW_LISTENER_CONSTANTS.CALENSO_CHILD_TO_PARENT_EVENT,
          note: 'Child To Parent Communication Done',
          step: eventName,
        }
      );
    }
  }

  emitSurveyEvent(data: { eventName: string, eventStep: number, stepName: string }): void {
    // Google Tag Manager
    try {
      dataLayer.push({
        event: data.eventName,
        step: data.eventStep,
        stepName: data.stepName
      });
      LoggerService.log(`[GOOGLE_TAG]:[${data.eventName}] event was fired.`);
    } catch (error) {
      LoggerService.warn('Datalayer not defined error is handled');
    }

    // Adobe launch tracking
    try {
      const type = 'TRACKER_TRIGGER_VIEW_END';
      const someClick = new CustomEvent(type, {
        detail: {
          name: data.eventName
        }
      });
      window.dispatchEvent(someClick);
      LoggerService.log(`[ADOBE_LAUNCH_TRACKING]:[${data.eventName}] event was fired.`);
    } catch (error) {
      LoggerService.warn('Adobe event error handler');
    }

    // Only enterprise partners can access this feature
    if ((this._partnerSubscriptionPlan?.subscription_type_id === SUBSCRIPTION_CONSTANTS.SUBSCRIPTION_NEW_ENTERPRISE_TYPE.id ||
      this._partnerSubscriptionPlan?.subscription_type_id === SUBSCRIPTION_CONSTANTS.SUBSCRIPTION_ENTERPRISE_TYPE.id)) {
      !this.sessionUuid && this.generateSessionUuid();
      this.emitBookingDetails(data.eventName);
    }
  }

  private emitBookingDetails(eventName: string, metaData: any = null): void {
    const param = {
      session_id: this.sessionUuid,
      widget_type: 'smart_widget',
      event: eventName,
      appointment_type: this.extractBookingType(eventName),
      ...metaData && { event_meta: JSON.stringify(metaData) }
    };
    this.updateBookingDetails(param)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        error: error => LoggerService.log(error)
      });
  }

  private extractBookingType(eventName: string): string | null {
    if (eventName.startsWith('appointment_')) {
      return 'appointment';
    } else if (eventName.startsWith('survey_')) {
      return 'survey';
    }
    return null;
  }

  private updateBookingDetails(params: Partial<StatisticsTraceModel>): Observable<StatisticsTraceModel> {
    return this.http.post<StatisticsTraceModel>(environment.restAPI + 'statistics/trace', params, {});
  }

  generateSessionUuid(): void {
    this.sessionUuid = uuidv4();
  }
}
