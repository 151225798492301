<div class="row cbsw-slots-selection-step">
  <div class="col-md-12 my-2 cbsw-slots-selection-step-title">
    <app-csw-text
      [text]="bookingService.widgetTemplates | calioMeetingTemplate : { id: 132, type: 'template' } | translation : 'template_content' : lang"
      customClass="step-element-title fw-600"
    />
  </div>
  @if (widgetService.widgetConf?.context?.show_worker_preview_on_date_selection_step && selectedWorker.id) {
    <div class="col-md-12 cbsw-worker-preview-wrapper">
      <div class="row">
        <div class="col-md-6">
          <div class="cbsw-worker-preview">
            <div class="avatar d-flex justify-content-center align-items-start">
              @if (selectedWorker.avatar !== null) {
                <img
                  #image
                  class="resource-avatar rounded-circle"
                  src="{{ workerAvatarBaseUrl }}{{ selectedWorker.uuid }}/thumb_{{ selectedWorker.avatar }}"
                  (error)="
                    image.src = baseUrl + '/img/sample-avatar.png';
                    image.classList.add('default-avatar-margin')
                  "
                  height="200"
                />
              } @else {
                <img
                  alt="Worker Avatar"
                  class="default-avatar rounded-circle default-avatar-margin"
                  src="{{ baseUrl }}/img/sample-avatar.png"
                  height="200"
                />
              }
            </div>

            <div class="cbw-worker-detail-description">
              <div class="cbw-worker-title">
                @if (selectedWorker.is_resource === 0) {
                  {{ selectedWorker.resource_name }}
                } @else {
                  {{ worker | translation: 'booking_label': lang }}
                }
              </div>
              @if (selectedWorker.is_resource === 0 && selectedWorker.job_title?.trim()) {
                <div
                  class="cbw-worker-description"
                  [innerHTML]="(selectedWorker | translation: 'job_title': lang) | trustHtml"
                ></div>
              } @else if (selectedWorker.is_resource !== 0 && selectedWorker.description?.trim()) {
                <div
                  class="cbw-worker-description"
                  [innerHTML]="(selectedWorker | translation: 'description': lang) | trustHtml"
                ></div>
              }

              @if (+this.bookingService.partnerData?.widget_show_resource_store_name === 1) {
                <div class="cbw-worker-store-location">
                  <span class="cbw-worker-store-location-name">{{ selectedWorker.store | translation: 'name' : lang }}</span>
                  @if (selectedWorker.store.is_online === 0) {
                    <span class="cbw-worker-store-location-separator">, </span>
                    <span class="cbw-worker-store-location-address">
                      @if (selectedWorker.store.street) {
                        <span>{{ selectedWorker.store.street }}</span>
                      }
                      @if (selectedWorker.store.zip) {
                        <span>,&nbsp;{{ selectedWorker.store.zip }}</span>
                      }
                      @if (selectedWorker.store.city) {
                        <span>&nbsp;{{ selectedWorker.store.city }}</span>
                      }
                      @if (selectedWorker.store.country) {
                        <span>,&nbsp;{{ selectedWorker.store.country | translation: 'name' : lang }}</span>
                      }
                    </span>
                  }
                </div>
              }

              @if (selectedWorker?.attributes?.length) {
                @for (attribute of selectedWorker.attributes; track attribute.uuid) {
                  @if (attribute.type === 'select') {
                    <div class="attribute-container cbw-attribute-container">
                      <div class="cbw-attribute">
                        {{ attribute | translation: 'name': lang }}:
                      </div>
                      <div class="attribute-value-section">
                        @for (value of attribute.attributes_values; track value.uuid; let last = $last) {
                          <div class="attribute-value-img">
                            @if (value.icon_url !== null) {
                              <img
                                #image
                                class="cbw-attribute-icon"
                                alt="{{ value.value }}"
                                src="{{ value.icon_url }}"
                                (error)="image.src= baseUrl + '/img/sample-avatar.png'"
                              />
                            }
                            <div class="cbw-attribute-value">
                              {{ value | translation: 'value': lang }}
                              @if (!last) { | }
                            </div>
                          </div>
                        }
                      </div>
                    </div>
                  } @else {
                    <div class="cbw-attribute cbw-attribute-container">
                      {{ attribute | translation: 'name': lang }}:
                      @if (attribute.attributes_values?.length) {
                        {{ +attribute.attributes_values[0].value === 1 ? ( 'yes' | translate ): ( 'no' | translate ) }}
                      }
                    </div>
                  }
                }
              }
            </div>
          </div>
        </div>
        <!-- Empty <div> is required to maintain the row structure. -->
        <div class="col-md-6"></div>
      </div>
    </div>
  }
  <div class="col-md-6 cbsw-calendar-picker">
    @if (loadCalendarPicker) {
      <app-calio-calendar-picker
        [selectedBookingCartItems]="bookingService.selectedBookingData.cartItems"
        [displayBadgeInBookingFeature]="bookingService.partnerData.is_cart_supported === 1"
        [selectedDate]="selectedDate"
        [disableAutomaticSearch]="bookingService.partnerData.disable_automatic_search"
        [futureBookingThreshold]="bookingService.partnerData.future_booking_threshold_appointments"
        [availableDays]="availableDays"
        [disableAllDays]="true"
        [isMultiselect]="false"
        (selectDaysEvent)="onSelectedDays($event)"
        (monthChangedEvent)="onMonthChangedEvent($event)"
      />
    }
  </div>
  <div class="col-md-6 cbsw-slots-card-selection" id="slotsCardSection">
    <div class="mt-4 mt-md-0" >
      @if (showSlotGhostElement) {
        <div class="skeleton-box-card">
          <div>
            <div class="row">
              @for (ghostElement of [0, 1, 2, 3]; track ghostElement) {
                <div class="col-md-3 mb-4">
                  <div class="d-flex stats-card shadow p-2">
                    <div class="skeleton-animation w-100 d-flex justify-content-center flex-column align-items-center">
                      <div>
                        <div class="stat-box"></div>
                        <div class="stat-box mt-1"></div>
                      </div>
                    </div>
                  </div>
                </div>
              }
              @for (ghostElement of slotGhostElements; track ghostElement) {
                <div class="col-xs-12 col-sm-6 mb-4">
                  <div class="d-flex stats-card shadow">
                    <div class="skeleton-animation w-100 d-flex justify-content-center flex-column align-items-center">
                      <div>
                        <div class="stat-box-1"></div>
                        <div class="stat-box mt-1"></div>
                      </div>
                    </div>
                  </div>
                </div>
              }
            </div>
          </div>
        </div>
      } @else {
        @if (noAppointments) {
          <div
            class="alert alert-warning"
            [innerHTML]="(bookingService.widgetTemplates | calioMeetingTemplate : { id: 241, type: 'template' } | translation : 'template_content' : lang) | calioSafe: 'html'"
          >
          </div>
        }
        @if (freeAppointments?.length) {
          <app-slots-card-selection
            [freeAppointments]="freeAppointments"
            [noAppointments]="noAppointments"
            [freeAppointmentsCount]="freeAppointmentsCount"
            (gotoNextPageEvent)="next()"
          />
        }
      }
    </div>
  </div>
</div>

@if (showSlotsSelectionRequiredError) {
  <div class="cbsw-meeting-type-selection-setp-error cbsw-global-step-error">
    <div class="text-danger">
      <div>{{ 'error_messages.select_one_option' | translate }}</div>
    </div>
  </div>
}

<div class="d-flex justify-content-end mt-4 mb-4 cbw-custom-navigation">
  <div class="me-2">
    <app-button
      id="{{ 'slot-selection-previous-btn' }}"
      (clickEvent)="previous()"
      [fontColor]="widgetService?.widgetConf?.button_font_color"
      [backgroundColor]="widgetService?.widgetConf?.button_background_color"
    >
      <span class="custom-button-icon back"></span>
      {{ bookingService.widgetTemplates | calioMeetingTemplate : { id: 129, type: "template" } | translation : "template_content" : lang }}
    </app-button>
  </div>
  <div>
    <app-button
      id="{{ 'slot-selection-next-btn' }}"
      (clickEvent)="next()"
      [fontColor]="widgetService?.widgetConf?.button_font_color"
      [backgroundColor]="widgetService?.widgetConf?.button_background_color"
    >
      {{ bookingService.widgetTemplates | calioMeetingTemplate : { id: 128, type: "template" } | translation : "template_content" : lang }}
      <span class="custom-button-icon next"></span>
    </app-button>
  </div>
</div>
