import { Injectable } from '@angular/core';
import { FeaturesDao } from '@models/partner-db.model';

@Injectable({
  providedIn: 'root'
})
export class FeatureService {
  private featuresList: { [key: string]: boolean } = {};

  loadSubscriptionFeatures(features: FeaturesDao[]): void {
    features?.length && features.forEach(feature => this.featuresList[feature.feature.name] = true);
  }

  hasFeature(feature: string): boolean {
    return this.featuresList[feature] || false;
  }
}
